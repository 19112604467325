import { useState, useEffect } from 'react';
import axios from 'axios';


const defaultInput = {
    name: '',
    brand: '',
    manufacturerId: '668182770c613aa2fb66a222',
    image_url: '',
    strain: 'Sativa',
    category: 'Flower',
    price: 12,
    thc_level: 23,
    cbd_level: 0,
    weight: 0.2,
    amount_in_stock: 45,
    description: 'This brand is the best brand on the market.',
};


function DevProducts() {
    const [state, setState] = useState({
        data: {},
        products: []
    });

    const [productInput, setProductInput] = useState(defaultInput);
    const [activeManufacturer, setActiveManufacturer] = useState(null);

    useEffect(() => {
        fetchProducts();
    }, [])

    const fetchProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/product`);
            setState({
                data: res.data,
                products: res.data.products
            });
            // Set the first manufacturer as active by default
            if (res.data.products.length > 0 && activeManufacturer === undefined) {
                setActiveManufacturer(res.data.products[0].manufacturerId);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductInput(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/product`, productInput);
            console.log(response.data);
            fetchProducts(); // Refresh the product list
            setProductInput(defaultInput); // Clear the input
        } catch (error) {
            console.error('Error:', error.response.data.error);
        }
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/product/${id}`);
            fetchProducts(); // Refresh the product list
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Product Management</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Product Name:</label>
                            <input 
                                type="text" 
                                id="name"
                                name="name"
                                value={productInput.name} 
                                onChange={handleInputChange} 
                                placeholder="Enter product name"
                            />
                            <label htmlFor="price">Price:</label>
                            <input 
                                type="number" 
                                id="price"
                                name="price"
                                value={productInput.price} 
                                onChange={handleInputChange} 
                                placeholder="Enter product price"
                            />
                            <label htmlFor="manufacturerId">Manufacturer ID:</label>
                            <input 
                                type="text" 
                                id="manufacturerId"
                                name="manufacturerId"
                                value={productInput.manufacturerId} 
                                onChange={handleInputChange} 
                                placeholder="Enter manufacturer ID"
                            />
                            <label htmlFor="strain">Strain:</label>
                            <select id="strain" name="strain" value={productInput.strain} onChange={handleInputChange}>
                                <option value="Indica">Indica</option>
                                <option value="Sativa">Sativa</option>
                                <option value="Hybrid">Hybrid</option>
                            </select>
                            <label htmlFor="thc_level">THC Content:</label>
                            <input 
                                type="number" 
                                id="thc_level"
                                name="thc_level"
                                value={productInput.thc_level} 
                                onChange={handleInputChange} 
                                placeholder="Enter THC level"
                            />
                            <label htmlFor="cbd_level">CBD Content:</label>
                            <input 
                                type="number" 
                                id="cbd_level"
                                name="cbd_level"
                                value={productInput.cbd_level} 
                                onChange={handleInputChange} 
                                placeholder="Enter CBD level"
                            />
                            <label htmlFor="category">Category:</label>
                            <select id="category" name="category" value={productInput.category} onChange={handleInputChange}>
                                <option value="Flower">Flower</option>
                                <option value="Edible">Edibles</option>
                                <option value="Pre-roll">Pre-roll</option>
                                <option value="Concentrate">Concentrate</option>
                                <option value="Vape">Vape</option>
                                <option value="Cartridge">Cartridge</option>
                                <option value="Accessory">Accessory</option>
                                <option value="Other">Other</option>
                            </select>
                            <label htmlFor="description">Description:</label>
                            <textarea 
                                id="description"
                                name="description"
                                value={productInput.description} 
                                onChange={handleInputChange} 
                                placeholder="Enter product description"
                            />
                            <label htmlFor="weight">Weight:</label>
                            <input 
                                type="number" 
                                id="weight"
                                name="weight"
                                value={productInput.weight} 
                                onChange={handleInputChange} 
                                placeholder="Enter product weight"
                            />
                            <label htmlFor="image_url">Image URL:</label>
                            <input 
                                type="text" 
                                id="image_url"
                                name="image_url"
                                value={productInput.image_url} 
                                onChange={handleInputChange} 
                                placeholder="Enter image URL"
                            />
                            <button type="submit">Add Product</button>
                        </form>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <h4>Product List (Total: {state.products.length})</h4>
                        <ul className="nav nav-tabs" id="productTabs" role="tablist">
                            {Array.from(new Set(state.products.map(product => product.manufacturerId))).map((manufacturerId, index) => (
                                <li className="nav-item" key={manufacturerId}>
                                    <a 
                                        className={`nav-link ${activeManufacturer === manufacturerId ? 'active' : ''}`}
                                        id={`${manufacturerId}-tab`} 
                                        data-toggle="tab" 
                                        href={`#${manufacturerId}`} 
                                        role="tab" 
                                        aria-controls={manufacturerId} 
                                        aria-selected={activeManufacturer === manufacturerId}
                                        onClick={() => setActiveManufacturer(manufacturerId)}
                                    >
                                        {manufacturerId} ({state.products.filter(product => product.manufacturerId === manufacturerId).length})
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content" id="productTabsContent">
                            {Array.from(new Set(state.products.map(product => product.manufacturerId))).map((manufacturerId, index) => (
                                <div 
                                    className={`tab-pane fade ${activeManufacturer === manufacturerId ? 'show active' : ''}`} 
                                    id={manufacturerId} 
                                    role="tabpanel" 
                                    aria-labelledby={`${manufacturerId}-tab`}
                                    key={manufacturerId}
                                >
                                    <ul>
                                        {state.products.filter(product => product.manufacturerId === activeManufacturer).map((product) => (
                                            <li key={product._id}>
                                                <pre>
                                                   {JSON.stringify(product, null, 4)}
                                                </pre>
                                                <button onClick={() => handleDelete(product._id)}>Delete</button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

/*

Dank | Alaskan Thunder Fuck | Flower - 12 - Indica - Flower - 667b82f66d134da54b5f9643 - https://rudera-product-images.s3.amazonaws.com/dank_alaskan_tf1.jpgDelete
Dank | Pineapple Express | Vape - 45 - Indica - Vape - 667b830a6d134da54b5f9646 - https://rudera-product-images.s3.amazonaws.com/dank_vape_pe1.avifDelete
Ayrloom Island Time | Gummies - 21 - Hybrid - Edibles - 667b83146d134da54b5f9649 - https://rudera-product-images.s3.amazonaws.com/ayrloom.jpgDelete
40Tons | Flower | Lemon Cherry Gelato - 44 - Indica - Flower - 667b83306d134da54b5f9657 - https://rudera-product-images.s3.amazonaws.com/40tons.jpgDelete
*/

export default DevProducts;
