import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DevManufacturer() {
    const [state, setState] = useState({
        data: {},
        manufacturers: []
    });

    const [manufacturerInput, setManufacturerInput] = useState({
        name: '',
        emailAddress: '',
        license: '',
        state: '',
        country: '',
        address: '',
        zipcode: '',
        city: '',
        phoneNumber: '',
        accountType: 'Manufacturer',
        salesType: 'Any',
        logoUrl: '',
        websiteUrl: ''
    });

    useEffect(() => {
        fetchManufacturers();
    }, [])

    const fetchManufacturers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/manufacturer`);
            setState({
                data: res.data,
                manufacturers: res.data.manufacturers
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setManufacturerInput(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/manufacturer`, manufacturerInput);
            console.log(response.data);
            fetchManufacturers(); // Refresh the manufacturer list
            setManufacturerInput({
                name: '',
                emailAddress: '',
                license: '',
                state: '',
                country: '',
                address: '',
                zipcode: '',
                city: '',
                phoneNumber: '',
                accountType: 'Manufacturer',
                salesType: 'Any',
                logoUrl: '',
                websiteUrl: ''
            }); // Clear the input
        } catch (error) {
            console.error('Error:', error.response.data.error);
        }
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/manufacturer/${id}`);
            fetchManufacturers(); // Refresh the manufacturer list
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Manufacturer Management</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Manufacturer Name:</label>
                            <input 
                                type="text" 
                                id="name"
                                name="name"
                                value={manufacturerInput.name} 
                                onChange={handleInputChange} 
                                placeholder="Enter manufacturer name"
                                required
                            />
                            <label htmlFor="emailAddress">Email Address:</label>
                            <input 
                                type="email" 
                                id="emailAddress"
                                name="emailAddress"
                                value={manufacturerInput.emailAddress} 
                                onChange={handleInputChange} 
                                placeholder="Enter email address"
                                required
                            />
                            <label htmlFor="license">License:</label>
                            <input 
                                type="text" 
                                id="license"
                                name="license"
                                value={manufacturerInput.license} 
                                onChange={handleInputChange} 
                                placeholder="Enter license"
                                required
                            />
                            <label htmlFor="state">State:</label>
                            <input 
                                type="text" 
                                id="state"
                                name="state"
                                value={manufacturerInput.state} 
                                onChange={handleInputChange} 
                                placeholder="Enter state"
                                required
                            />
                            <label htmlFor="country">Country:</label>
                            <input 
                                type="text" 
                                id="country"
                                name="country"
                                value={manufacturerInput.country} 
                                onChange={handleInputChange} 
                                placeholder="Enter country"
                                required
                            />
                            <label htmlFor="address">Address:</label>
                            <input 
                                type="text" 
                                id="address"
                                name="address"
                                value={manufacturerInput.address} 
                                onChange={handleInputChange} 
                                placeholder="Enter address"
                                required
                            />
                            <label htmlFor="zipcode">Zipcode:</label>
                            <input 
                                type="text" 
                                id="zipcode"
                                name="zipcode"
                                value={manufacturerInput.zipcode} 
                                onChange={handleInputChange} 
                                placeholder="Enter zipcode"
                                required
                            />
                            <label htmlFor="city">City:</label>
                            <input 
                                type="text" 
                                id="city"
                                name="city"
                                value={manufacturerInput.city} 
                                onChange={handleInputChange} 
                                placeholder="Enter city"
                                required
                            />
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <input 
                                type="tel" 
                                id="phoneNumber"
                                name="phoneNumber"
                                value={manufacturerInput.phoneNumber} 
                                onChange={handleInputChange} 
                                placeholder="Enter phone number"
                                required
                            />
                            <label htmlFor="salesType">Sales Type:</label>
                            <select
                                id="salesType"
                                name="salesType"
                                value={manufacturerInput.salesType}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="Recreational">Recreational</option>
                                <option value="Medical">Medical</option>
                                <option value="Any">Any</option>
                            </select>
                            <label htmlFor="logoUrl">Logo URL (optional):</label>
                            <input 
                                type="url" 
                                id="logoUrl"
                                name="logoUrl"
                                value={manufacturerInput.logoUrl} 
                                onChange={handleInputChange} 
                                placeholder="Enter logo URL (optional)"
                            />
                            <label htmlFor="websiteUrl">Website URL (optional):</label>
                            <input 
                                type="url" 
                                id="websiteUrl"
                                name="websiteUrl"
                                value={manufacturerInput.websiteUrl} 
                                onChange={handleInputChange} 
                                placeholder="Enter website URL (optional)"
                            />
                            <button type="submit">Add Manufacturer</button>
                        </form>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <h4>Manufacturer List</h4>
                        <ul>
                            {state.manufacturers.map((manufacturer) => (
                                <li key={manufacturer._id}>
                                    {manufacturer.name} - {manufacturer._id}
                                    <button onClick={() => handleDelete(manufacturer._id)}>Delete</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DevManufacturer;
