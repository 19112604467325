import { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { Link } from 'react-router-dom';

const ManifestCellRenderer = (props: any) => {
  const handleClick = () => {
    if (props.onDownload) {
      props.onDownload(props.data);
    }
  };

  return (
    <div className="manifest-cell">
      <span>{props.value} </span>
      <FontAwesomeIcon 
        icon={faDownload} 
        className="download-icon" 
        onClick={handleClick}
      />
    </div>
  );
};

function OrdersTable() {
  const [rowData, setRowData] = useState([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    fetchOrders();
  }, [user]);

  const fetchOrders = async () => {
    try {
      let res: any = {}

      if (user.mongo_user.account_type == 'Manufacturer') {
        res = await axios.get(`${process.env.REACT_APP_API_URL}/order/manufacturer/${user.mongo_user.manufacturerId}`);
      }
      if (user.mongo_user.account_type == 'Dispensary') {
        res = await axios.get(`${process.env.REACT_APP_API_URL}/order/dispenser/${user.mongo_user.dispenserId}`);
      }
      
      setRowData(res?.data.orders);
    } catch (err) {
    }
  }

  const [colDefs, setColDefs] = useState<ColDef[]>([
    { 
      field: "_id", 
      headerName: "Order No",
      cellRenderer: (params: any) => (
        <Link to={`/order/${params.value}`}>{params.value}</Link>
      )
    },
    { 
      field: "subTotal", 
      headerName: "Total",
      cellRenderer: (params: any) => {
        return <span>${params.value.toFixed(2)}</span>;
      }
    },
    { 
      field: "deliveryStatus", 
      headerName: "Status",
      cellRenderer: (params: any) => {
        let className = '';
        let pillColor = '';
        switch (params.value) {
          case 'pending':
            className = 'status-pending';
            pillColor = '#FFA500'; // Orange
            break;
          case 'paid':
            className = 'status-paid';
            pillColor = '#4CAF50'; // Green
            break;
          case 'failed':
            className = 'status-failed';
            pillColor = '#F44336'; // Red
            break;
          case 'refunded':
            className = 'status-refunded';
            pillColor = '#2196F3'; // Blue
            break;
          default:
            className = 'status-default';
            pillColor = '#9E9E9E'; // Grey
        }
        return (
          <span 
            className={`status-pill ${className}`}
            style={{
              backgroundColor: pillColor,
              padding: '4px 8px',
              borderRadius: '12px',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '0.8em'
            }}
          >
            {params.value}
          </span>
        );
      }
    },
    { field: "shippingAddress", headerName: "Shipping Address" },
    { 
      field: "products", 
      headerName: "Product Count",
      cellRenderer: (params: any) => {
        return <span>{params.value.length}</span>;
      }
    },
    { 
      field: "orderDate", 
      headerName: "Date", 
      cellRenderer: (params: any) => {
        return <span>{new Date(params.value).toDateString()} {new Date(params.value).toLocaleTimeString()}</span>;
      }
    },
    { 
      field: "manifest", 
      headerName: "Manifest",
      cellRenderer: (params: any) => (
        <ManifestCellRenderer 
          value="Manifest"
          data={params.data}
          onDownload={handleDownload} 
        />
      )
    },
  ]);

  const handleDownload = (data: any) => {
    console.log(`Downloading manifest for order: ${data.orderId}`);
  };

  const autoSizeStrategy = {
    type: 'fitGridWidth' as const,
    defaultMinWidth: 100,
    columnLimits: [
      {
        colId: '_id',
        maxWidth: 250
      },
      {
        colId: 'subTotal',
        maxWidth: 50
      },
      {
        colId: 'deliveryStatus',
        maxWidth: 50
      },
      {
        colId: 'shippingAddress',
        maxWidth: 250
      },
      {
        colId: 'products',
        maxWidth: 150
      },
      {
        colId: 'orderDate',
        minWidth: 200
      },
      {
        colId: 'manifest',
        minWidth: 150
      }
    ]
  };

  return (
    <div className="ag-theme-quartz" style={{ height: 500, margin: "100px 32px"}}>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        autoSizeStrategy={autoSizeStrategy}
      />
    </div>
  );
}

export default OrdersTable;
