import NavSidebar from '../components/Dashboard/NavSidebar';
import TopNavbar from '../components/Dashboard/TopNavbar';
import AddProductForm from '../components/ProductsGrid/AddProduct';

function ProductAdd() {
    return (
            <div className="main">
                <TopNavbar/>
                <NavSidebar/>
                <div className="dashboard-content">
                    <AddProductForm/>
                </div>
            </div>
        );
}

export default ProductAdd;
