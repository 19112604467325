import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuantity, clearCart, removeItem, selectCartItemsCount } from '../../features/cart/cartSlice'; // Assuming your slice is named cartSlice
import { setError, setLoading } from '../../features/products/productsSlice';
import { selectUser } from '../../features/user/userSlice';


function InsideCart({
    minQuantity = 5,
    maxQuantity = 100
}) {
    const dispatch = useDispatch();
    const cartItems = useSelector((state: { cart: { items} }) => state.cart.items);
    const totalItems = useSelector(selectCartItemsCount);
    const user = useSelector(selectUser);

    const [inputValues, setInputValues] = useState({});
    const [orderSuccess, setOrderSuccess] = useState<boolean | null>(null);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    useEffect(() => {
        if (orderSuccess !== null) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 3000); // Alert will disappear after 3 seconds

            return () => clearTimeout(timer);
        }
    }, [orderSuccess]);

    const handleIncrement = (id: string, quantity: number) => {
        quantity = quantity + 1;
        if (quantity <= maxQuantity) {
            dispatch(updateQuantity({ id, quantity }))
            setInputValues(prev => ({ ...prev, [id]: quantity }));
        }
    };

    const handleDecrement = (id: string, quantity: number) => {
        quantity = quantity - 1;
        if (quantity >= minQuantity) {
            dispatch(updateQuantity({ id, quantity }));
            setInputValues(prev => ({ ...prev, [id]: quantity }));
        }
    };

    const handleRemoveItem = (id: string) => {
        dispatch(removeItem(id));
      };

    const sendOrderSummaryEmail = async (order_id) => {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/order/email-summary/${order_id}`);
        console.log(res);
    }

    const handleOrder = async () => {
        dispatch(setLoading(true));
        try {
            console.log(cartItems)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/order`, {
                userId: user.mongo_user._id,
                dispenserId: user.mongo_user.dispenserId,
                products: cartItems.map(item => ({
                    productId: item._id,
                    quantity: item.quantity,
                    price: item.price
                })),
                subTotal: cartItems.reduce((total: number, item) => total + item.price * item.quantity, 0),
                
            });
            console.log("Order id");
            console.log(res.data.order._id);
            dispatch(clearCart());
            setOrderSuccess(true);
            sendOrderSummaryEmail(res.data.order._id);
        } catch (err) {
            console.log(err);
            dispatch(setError('Failed to place order.'));
            setOrderSuccess(false);
        } finally {
            dispatch(setLoading(false)); // Set loading to false whether fetch succeeds or fails
        }
    };

    function CartHtml() {
        const subtotal = cartItems.reduce((total: number, item) => total + item.price * item.quantity, 0);
        const taxes = subtotal * 0.1; // Assuming 10% tax rate, adjust as necessary
        const total = subtotal + taxes;
        if (totalItems > 0) {
            return (
                <div className='cart-container'>
                    <div className='cart-products-list'>
                        {cartItems.map((item) => (
                            <div key={item._id} className='cart-products-list-item'>
                                <h4>{item.name}</h4>
                                <p className='cart-price'>${item.price.toFixed(2)}</p>
                                <div className='quantity-container'>
                                    <button className="quantity-button" onClick={() => handleDecrement(item._id, item.quantity)}>-</button>
                                    <input 
                                        className="quantity-input"
                                        type="number"
                                        value={inputValues[item._id] || item.quantity.toString()}
                                        readOnly={true}
                                        // onChange={(e) => handleQuantityChange(item._id, e.target.value)}
                                        // onBlur={() => handleQuantityBlur(item._id)}
                                    />
                                    <button className="quantity-button" onClick={() => handleIncrement(item._id, item.quantity)}>+</button>
                                </div>
                                <p><a
                                    className='remove-item'
                                    onClick={() => handleRemoveItem(item._id)}
                                >
                                    Remove item
                                </a></p>
                            </div>
                        ))}
                    </div>
                    <div className='cart-products-cost'>
                        <div>
                            <h5>Subtotal ({totalItems} items): ${subtotal.toFixed(2)}</h5>
                            <h5>Est. Taxes: ${taxes.toFixed(2)}</h5>
                            <h5>Estimated Total: ${total.toFixed(2)}</h5>
                        </div>
                        <button className="cart-order-btn d-block btn btn-bordered-white mt-4" onClick={handleOrder}>
                            Place Order
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <h5>Your cart is empty.</h5>
            );
        }
    }

    return (
        <section className="inside-cart">
            <h1>Cart</h1>
            {showAlert && orderSuccess && <div className="cart-alert alert-success">Order submitted successfully! Please check your email for confirmation.</div>}
            {showAlert && orderSuccess === false && <div className="cart-alert alert-danger">Failed to place order. Please try again.</div>}
            <CartHtml/>
        </section>
    );
}

export default InsideCart;
