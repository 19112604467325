import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SignIn, SignedOut, useUser } from '@clerk/clerk-react';

function LoginSection() {
  const history = useHistory();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if ((isLoaded && isSignedIn && user)) {
      // Redirect to dashboard page
      history.push('/dashboard');
    }
  }, [user]);

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      color: 'black',
    }}>
      <SignedOut>
        <div>
          <SignIn forceRedirectUrl="/dashboard"/>
        </div>
      </SignedOut>
    </div>
  );
}

export default LoginSection;
