import React, { useState, useEffect } from 'react';
import axios from 'axios';



function ManufacturerProducts({manufacturerId}) {
    const [products, setProducts] = useState<any[]>([]);

    useEffect(() => {
        fetchProducts();
    }, [manufacturerId]);

    const fetchProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/product/manufacturer/${manufacturerId}`);
            setProducts(res.data.products);
        } catch (err) {
            console.log(err);
        }
    }

    const handleQuantityChange = async (productId: string, newQuantity: number) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/product/${productId}`, {
                amount_in_stock: newQuantity
            });
            setProducts(products.map(product => 
                product._id === productId ? {...product, amount_in_stock: newQuantity} : product
            ));
        } catch (err) {
            console.log(err);
        }
    }

    const handlePriceChange = async (productId: string, newPrice: number) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/product/${productId}`, {
                price: newPrice
            });
            setProducts(products.map(product => 
                product._id === productId ? {...product, price: newPrice} : product
            ));
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Manufacturer Products</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <h4>Product List</h4>
                        <ul>
                            {products.map((product) => (
                                <li key={product._id}>
                                    {product.name} - 
                                    Price: $
                                    <input 
                                        type="number" 
                                        value={product.price} 
                                        onChange={(e) => handlePriceChange(product._id, parseFloat(e.target.value))}
                                        min="0"
                                        step="0.01"
                                    /> - 
                                    Quantity: 
                                    <input 
                                        type="number" 
                                        value={product.amount_in_stock} 
                                        onChange={(e) => handleQuantityChange(product._id, parseInt(e.target.value))}
                                        min="0"
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ManufacturerProducts;
