import { useState } from 'react';

const data = {
    "preHeading": "How It Works",
    "heading": "Our one-stop shop marketplace",
    "workData": [
      {
        "id": 1,
        "icon": "icons icon-grid text-effect",
        "title": "Inventory",
        "text": "Purchase a diverse selection of cannabis products from the latest brands to stock your dispensary with. Pre-rolls, flower, gummies, etc."
      },
      {
        "id": 2,
        "icon": "icons icon-wallet text-effect",
        "title": "Payments",
        "text": "All payments are processed on the platform. Access to flexible financial solutions for both cultivators and retailers."
      },
      {
        "id": 3,
        "icon": "icons icon-drawer text-effect",
        "title": "Tracking",
        "text": "Get real-time tracking information on your shipments. Communicate with distributors directly about your orders."
      },
      {
        "id": 4,
        "icon": "icons icon-bag text-effect",
        "title": "Delivery",
        "text": "Upon order delivery, seamlessly upload new product information into your dispensary's inventory management software."
      }
    ]
  }
  

function Work() {
    const [state, setState] = useState(
        {
            data: data,
            workData: data.workData
        }
    ) as any;

    return (
        <section className="work-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro mb-4">
                            <div className="intro-content">
                                {/* <span>{state.data.preHeading}</span> */}
                                <h3 className="mt-3 mb-0">{state.data.heading}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row items">
                    {state.workData.map((item, idx) => {
                        return (
                            <div key={`wd_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                {/* Single Work */}
                                <div className="single-work">
                                    <i className={item.icon} />
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default Work;
