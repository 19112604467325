import NavSidebar from '../components/Dashboard/NavSidebar';
import ItemDetail from '../components/ItemDetails/ItemDetails';
import TopNavbar from '../components/Dashboard/TopNavbar';

function ItemDetails() {
    return (
        <div className="main">
            <TopNavbar/>
            <NavSidebar/>
            <div className="dashboard-content">
                <ItemDetail/>
            </div>
        </div>
    );
}

export default ItemDetails;
