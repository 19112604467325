import { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Work from '../components/Work/Work';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import Quotes from '../components/Work/Quotes';

class Landing extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                {/* <Auctions /> */}
                {/* <TopSeller /> */}
                {/* <Collections /> */}
                <Work />
                <Quotes />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
    }
}

export default Landing;
