import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TopNavbar from '../Dashboard/TopNavbar';
import NavSidebar from '../Dashboard/NavSidebar';

function OrderDetails() {
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<any>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/order/${id}`);
        setOrder(res.data.order);
      } catch (err) {
        console.error('Error fetching order:', err);
      }
    };

    fetchOrder();
  }, [id]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main">
            <TopNavbar/>
            <NavSidebar/>
            
        <div className='order-content'>
          <div style={{marginLeft: "32px"}}>

          
            <h2>Order Details</h2>
            <p>Order ID: {order._id}</p>
            <p>Total Amount: ${order.subTotal}</p>
            <p>Status: {order.deliveryStatus}</p>
            <p>Shipping Address: {order.shippingAddress}</p>
            <p>Order Date: {new Date(order.orderDate).toLocaleString()}</p>
            <h3>Products:</h3>
            <ul>
              {order.products.map((product: any, index: number) => (
                <li key={index}>{product.name} - Quantity: {product.quantity}</li>
              ))}
            </ul>
          </div>
        </div>
    </div>
  );
}

export default OrderDetails;