import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DevDispenser from './DevDispenser';
import DevManufacturer from './DevManufacturer';
import DevProducts from './DevProducts';
import DevProductsCSV from './DevProductsCSV';
import DevUser from './DevUser';


function Dev() {
    return (
        <section className="activity-area load-more">
            <DevUser/>
            <DevDispenser/>
            <DevManufacturer/>
            <DevProductsCSV/>
            <DevProducts/>
        </section>
    );
}

export default Dev;
