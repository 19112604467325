import { useState } from 'react';

function Quotes() {
    const [quote, _] = useState(
        {
            quote: "\"We no longer buy inventory over email. Rudera simplified the entire process.\"",
            author: "- NY Retail Dispensary"
        }
    );

    return (
        <section className="quotes-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">{quote.quote}</h3>
                                <h3 className="mt-3 mb-0">{quote.author}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Quotes;
