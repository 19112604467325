import { useState, useEffect } from "react";
import { useAuth } from "@clerk/clerk-react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";

import Landing from "../themes/landing";
import ItemDetails from "../themes/item-details";
import Login from "../themes/login";
import Dashboard from "../themes/dashboard";
import Orders from "../themes/orders";
import ProductAdd from "../themes/productAdd";
import Cart from "../themes/cart";
import Dev from "../components/Dev/Dev";
import Manufacturer from "../components/Manufacturer/Manufacturer";
import Profile from "../themes/profile";
import OrderDetails from "../components/Orders/OrderDetails";
import Privacy from "../themes/privacy";
import FAQ from "../themes/faq";

const MobileMessage = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh',
    padding: "32px"
  }}>
    <h2>Only available on a desktop browser.</h2>
  </div>
);

const publicRoutes = [
  { path: "/", component: Landing, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/faq", component: FAQ, exact: true },
  { path: "/privacy", component: Privacy, exact: true },
];

const protectedRoutes = [
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/cart", component: Cart, exact: true },
  { path: "/profile", component: Profile, exact: true },
  { path: "/orders", component: Orders, exact: true },
  { path: "/add-product", component: ProductAdd, exact: true },
  { path: "/item-details", component: ItemDetails, exact: true },
  { path: "/admin-dev", component: Dev, exact: true },
  { path: "/manufacturer", component: Manufacturer, exact: true },
  { path: "/order/:id", component: OrderDetails, exact: false },
];

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { userId, isLoaded } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isLoaded && !userId) {
      history.push("/login");
    }
  }, [isLoaded, userId, history]);

  if (!isLoaded) {
    return null; // or a loading spinner
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        userId ? <Component {...props} /> : null
      }
    />
  );
};

const Routes = ({ isMobile }) => (
  <Switch>
    {publicRoutes.map(({ path, component, exact }, index) => (
      <Route key={index} path={path} component={component} exact={exact} />
    ))}
    {!isMobile && protectedRoutes.map(({ path, component, exact }, index) => (
      <ProtectedRoute key={index} path={path} component={component} exact={exact} />
    ))}
  </Switch>
);

const AuthCheck = () => {
  const { isLoaded } = useAuth();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (!isLoaded) {
    return null; // or a loading spinner
  }

  if (isMobile && history.location.pathname !== "/") {
    return <MobileMessage />;
  }

  return <Routes isMobile={isMobile} />;
};

const MyRoutes = () => (
  <Router>
    <AuthCheck />
  </Router>
);

export default MyRoutes;
