import { useState, useEffect } from 'react';
import Quantity from './Quantity';
import { addItem } from '../../features/cart/cartSlice';
import { useAppDispatch } from '../../app/hooks';

const defaultImg = "/img/kaikas.png";

function DispensaryView({item}) {
    const dispatch = useAppDispatch();

    const [quantity, setQuantity] = useState(Math.min(10, item.amount_in_stock));
    const [addedToCart, setAddedToCart] = useState(false);

    useEffect(() => {
        setQuantity(Math.min(quantity, item.amount_in_stock));
    }, [item.amount_in_stock]);

    const handleQuantityChange = (newQuantity: number) => {
        setQuantity(Math.min(newQuantity, item.amount_in_stock));
        // TODO: MINOR - But this func may be running twice, when should only run once.
    };

    const handleAddToCart = () => {
        
        if (quantity > 1) {
            setAddedToCart(true);
            dispatch(addItem({
                ...item, quantity: quantity
            }));
            setAddedToCart(true);
            setTimeout(() => {
                setAddedToCart(false);
            }, 1000);
        }
        setQuantity(Math.min(10, item.amount_in_stock));
    }

    return (
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-12 col-lg-5">
                    <div className="item-info">
                        <div className="item-thumb text-center">
                            <img src={item.image_url ? item.image_url : defaultImg} alt={item.name} />
                        </div>
                        <div className="mt-3">
                            <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                                <li>
                                    <a className="active" id="nav-home-tab" data-toggle="pill" href="#nav-home">
                                        <h5 className="m-0">{"Product Details"}</h5>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    
                        {/* Tab Content */}
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home">
                                <div className="mt-3">
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    {/* Content */}
                    <div className="content mt-5 mt-lg-0">
                        <h3 className="m-0">{item.name}</h3>
                        {/* Manufacturer */}
                        <div className="owner d-flex align-items-center">
                            <span>Manufacturer </span>
                            <a className="owner-meta d-flex align-items-center ml-3">
                                <img className="avatar-sm rounded-circle" src={defaultImg} alt="" />
                                <h6 className="ml-2">HPI</h6>
                            </a>
                        </div>

                        <div className="product-details-container">
                            <div className="price-left">
                                <span className="price">${item.price}</span>
                            </div>
                            <div className="details-right">
                                <div className="detail-item">{"In Stock: " + item.amount_in_stock}</div>
                                <div className="detail-item">{"Unit Size: " + item.unit_size}</div>
                                <div className="detail-item">{"Category: " + item.category}</div>
                                <div className="detail-item">{"% THC: " + item.thc_level}</div>
                                <div className="detail-item">{"Strain: " + item.strain}</div>
                            </div>
                        </div>
                        
                        <div>
                            <h5>Quantity</h5>
                            <Quantity 
                                initialQuantity={quantity} 
                                minQuantity={1} 
                                maxQuantity={item.amount_in_stock} 
                                onQuantityChange={handleQuantityChange} 
                            />
                        </div>
                        <p className="d-block btn btn-bordered-white mt-4" onClick={handleAddToCart}>
                            {addedToCart ? '✓' : 'Add to cart'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

export default DispensaryView;
