import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../features/user/userSlice';


const SidebarItem = ({ text, link }) => {
  return (
    <a href={link} className="sidebar-item">
      {text}
    </a>
  );
};


function NavSidebar() {

  const user = useAppSelector(selectUser);

  const DispensaryViewItems = [
    { text: 'Marketplace', link: '/dashboard' },
    { text: 'Orders', link: '/orders' },
    // { text: 'Finances', link: '/finances' },
    // { text: 'Messages', link: '/messages' },
    // { text: 'Settings', link: '/settings' },
  ];

  const ManufacturerViewItems = [
    { text: 'Products', link: '/dashboard' },
    { text: 'Orders', link: '/orders' },
  ];

  const items = user.mongo_user.account_type === 'Dispensary' ? DispensaryViewItems : ManufacturerViewItems;

  return (
    <div className="nav-sidebar">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
              <SidebarItem text={item.text} link={item.link} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavSidebar;
