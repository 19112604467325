import ProfileDetails from '../components/Profile/Profile';
import NavSidebar from '../components/Dashboard/NavSidebar';
import TopNavbar from '../components/Dashboard/TopNavbar';

function Profile() {
    return (
        <div className="main">
            <TopNavbar/>
            <NavSidebar/>
            <div className="dashboard-content">
                <ProfileDetails/>
            </div>
        </div>
    );
}

export default Profile;
