import React, { useState } from 'react';
import axios from 'axios';

const defaultInput = {
    name: '',
    description: '',
    brand: '',
    price: '',
    amount_in_stock: '',
    manufacturer_sku: '',
    manufacturerId: '',
    strain: '',
    thc_level: '',
    cbd_level: '',
    category: '',
    weight: '',
    unit_size: '',
    minimum_order_amount: '',
}

const AddProductForm = () => {
  const [product, setProduct] = useState(defaultInput);

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/product`, product);
        console.log(response.data);
        // fetchProducts(); // Refresh the product list
        setProduct(defaultInput); // Clear the input
    } catch (error) {
        console.error('Error:', error.response.data.error);
    }
    console.log(product);
  };

  return (
    <section className="add-product-area">
        <form onSubmit={handleSubmit} style={{ display: 'grid', gridTemplateColumns: '150px 1fr', gap: '10px 20px' }}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" value={product.name} onChange={handleChange} required />

        <label htmlFor="description">Description:</label>
        <input type="text" id="description" name="description" value={product.description} onChange={handleChange} />

        <label htmlFor="brand">Brand:</label>
        <input type="text" id="brand" name="brand" value={product.brand} onChange={handleChange} />

        <label htmlFor="price">Price:</label>
        <input type="number" id="price" name="price" value={product.price} onChange={handleChange} required min="0" />

        <label htmlFor="amount_in_stock">Amount in Stock:</label>
        <input type="number" id="amount_in_stock" name="amount_in_stock" value={product.amount_in_stock} onChange={handleChange} min="0" />

        <label htmlFor="manufacturer_sku">Manufacturer SKU:</label>
        <input type="text" id="manufacturer_sku" name="manufacturer_sku" value={product.manufacturer_sku} onChange={handleChange} />

        <label htmlFor="manufacturerId">Manufacturer ID:</label>
        <input type="text" id="manufacturerId" name="manufacturerId" value={product.manufacturerId} onChange={handleChange} required />

        <label htmlFor="strain">Strain:</label>
        <select id="strain" name="strain" value={product.strain} onChange={handleChange}>
            <option value="">Select Strain</option>
            <option value="Indica">Indica</option>
            <option value="Sativa">Sativa</option>
            <option value="Hybrid">Hybrid</option>
        </select>

        <label htmlFor="thc_level">THC Level:</label>
        <input type="text" id="thc_level" name="thc_level" value={product.thc_level} onChange={handleChange} />

        <label htmlFor="cbd_level">CBD Level:</label>
        <input type="number" id="cbd_level" name="cbd_level" value={product.cbd_level} onChange={handleChange} />

        <label htmlFor="category">Category:</label>
        <select id="category" name="category" value={product.category} onChange={handleChange}>
            <option value="">Select Category</option>
            <option value="Flower">Flower</option>
            <option value="Edible">Edible</option>
            <option value="Pre-roll">Pre-roll</option>
            <option value="Concentrate">Concentrate</option>
            <option value="Vape">Vape</option>
            <option value="Cartridge">Cartridge</option>
            <option value="Accessory">Accessory</option>
            <option value="Other">Other</option>
        </select>

        <label htmlFor="weight">Weight:</label>
        <input type="number" id="weight" name="weight" value={product.weight} onChange={handleChange} min="0" />

        <label htmlFor="unit_size">Unit Size:</label>
        <input type="number" id="unit_size" name="unit_size" value={product.unit_size} onChange={handleChange} min="1" />

        <label htmlFor="minimum_order_amount">Minimum Order Amount:</label>
        <input type="number" id="minimum_order_amount" name="minimum_order_amount" value={product.minimum_order_amount} onChange={handleChange} min="1" />

        <button className="d-block btn btn-bordered-white" type="submit">Add Product</button>
        </form>
    </section>
  );
};

export default AddProductForm;
