import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser } from '../../features/user/userSlice';
import { setGridView } from '../../features/products/productsSlice';
import { Link } from 'react-router-dom';

const Toolbar = ({ onButtonClick }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const gridView = useAppSelector((state: { products: { gridView } }) => state.products.gridView);

    function DisplayToolbar() {
        if (user.mongo_user.account_type === 'Dispensary') {
            return (
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <button className="d-block btn btn-bordered-white" onClick={() => dispatch(setGridView(!gridView))}>{gridView ? 'Switch to Grid View' : 'Switch to Table View'}</button>
                </div>
            );
        } else {  // Manufacturer
            return (
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <button className="d-block btn btn-bordered-white" onClick={() => dispatch(setGridView(!gridView))}>{gridView ? 'Switch to Grid View' : 'Switch to Table View'}</button>
                    <Link to={{
                        pathname: '/add-product'
                    }}>
                        <button className="d-block btn btn-bordered-white">Add Product</button>
                    </Link>
                    <button className="d-block btn btn-bordered-white" onClick={() => onButtonClick('export')}>Export Products to CSV</button>
                </div>
            );
        }
    }

    return (
        <DisplayToolbar/>
    );
};

export default Toolbar;
