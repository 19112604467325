import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ManufacturerProducts from './ManufacturerProducts';
import ManufacturerOrders from './ManufacturerOrders';

function Manufacturer() {
    const [manufacturerId, setManufacturerId] = useState('668182770c613aa2fb66a222'); // Default manufacturer ID

    const handleManufacturerIdChange = (e) => {
        setManufacturerId(e.target.value);
    }

    return (
        <section className="activity-area load-more">
            <div className="row">
                    <div className="col-12">
                        <label htmlFor="manufacturerId">Manufacturer ID:</label>
                        <input 
                            type="text" 
                            id="manufacturerId"
                            value={manufacturerId} 
                            onChange={handleManufacturerIdChange} 
                            placeholder="Enter manufacturer ID"
                        />
                    </div>
                </div>
            <ManufacturerProducts manufacturerId={manufacturerId}/>
            <ManufacturerOrders manufacturerId={manufacturerId}/>
        </section>
    );
}

export default Manufacturer;

