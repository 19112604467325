import { Link } from 'react-router-dom';

function ItemCard({item}: any) {

    const defaultImg = "/img/kaikas.png";

    const itemClick = () => {
        console.log(item);
    };

    return (
        <Link to={{
            pathname: '/item-details',
            state: { item },
        }}>
            <div className="card" onClick={itemClick}>
                <div className="image-over">
                    <img className="card-img-top" src={item.image_url ? item.image_url : defaultImg} alt={item.name} />
                </div>
                {/* Card Caption */}
                <div className="card-caption col-12 p-0">
                    {/* Card Body */}
                    <div className="card-body">
                        <h5 className="mb-0">{item.name}</h5>
                        <div className="seller d-flex align-items-center my-3">
                            <span>{item.thc_level}% THC</span>
                        </div>
                        <div className="card-bottom d-flex justify-content-between">
                            <span>${item.price}</span>
                            <span>{item.amount_in_stock} in stock</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default ItemCard;
