import { useState } from 'react';

const data = {
    "img": "/img/logo.png",
    "widget_1": "Home",
    "widget_2": "Community",
    "widget_3": "Join us",
    "socialData": [
      {
        "id": 1,
        "link": "twitter",
        "icon": "fab fa-twitter",
        "url": "https://x.com/rudera_co"
      },
      {
        "id": 2,
        "link": "instagram",
        "icon": "fab fa-instagram",
        "url": "https://www.instagram.com/rudera.co/"
      }
    ],
    "widgetData_1": [
      {
        "id": 1,
        "text": "About"
      },
      {
        "id": 2,
        "text": "Marketplace"
      },
    ],
    "widgetData_2": [
      {
        "id": 1,
        "text": "FAQ",
        "link": "/faq"
      },
      {
        "id": 2,
        "text": "Privacy",
        "link": "/privacy"
      }
    ]
  }

function Footer() {
    const currentYear = new Date().getFullYear();
    const [state, setState] = useState(
        {
            data: data,
            socialData: data.socialData,
            widgetData_1: data.widgetData_1,
            widgetData_2: data.widgetData_2
        }
    )

    return (
        <footer className="footer-area">
            {/* Footer Top */}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 res-margin">
                            {/* Footer Items */}
                            <div className="footer-items">
                                <a className="navbar-brand" href="/">
                                    <h3>Rudera</h3>
                                </a>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {state.socialData.map((item, idx) => {
                                        return (
                                            <a key={`sd_${idx}`} className={item.link} href={item.url} target="_blank">
                                                <i className={item.icon} />
                                                <i className={item.icon} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 res-margin">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title">{state.data.widget_1}</h4>
                                <ul>
                                    {state.widgetData_1.map((item, idx) => {
                                        return (
                                            <li key={`wdo_${idx}`}><a href="#">{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 res-margin">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title">{state.data.widget_2}</h4>
                                <ul>
                                    {state.widgetData_2.map((item, idx) => {
                                        return (
                                            <li key={`wdo_${idx}`}><a href={item.link}>{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h4 className="footer-title">{state.data.widget_3}</h4>
                                {/* Subscribe Form */}
                                <div className="subscribe-form d-flex align-items-center">
                                    <input type="email" className="form-control" placeholder="info@rudera.co" />
                                    <button type="submit" className="btn"><i className="icon-paper-plane" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Bottom */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© {currentYear} Rudera Inc.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );

}

export default Footer;
