import React from 'react';

function FAQPage() {
  return (
    <section className="faq-section">
      <div className="container">
        <h1>Frequently Asked Questions (FAQ)</h1>
        
        <div className="faq-category">
          <h2>General Information</h2>
          <div className="faq-item">
            <h3>What is our marketplace?</h3>
            <p>We are a wholesale marketplace for cannabis dispensaries. We are the heartbeat of the legal NY cannabis market, connecting licensed dispensaries with cannabis brands.</p>
          </div>
        </div>
        
        <div className="faq-category">
          <h2>Ordering and Payments</h2>
          <div className="faq-item">
            <h3>What types of products can dispensaries purchase?</h3>
            <p>Dispensaries can purchase a diverse selection of cannabis products from the latest brands to stock their shelves with - pre-rolls, flower, gummies, etc.</p>
          </div>
          <div className="faq-item">
            <h3>How are payments processed?</h3>
            <p>All payments are processed on the platform. We also offer access to flexible financial solutions for both cultivators and retailers.</p>
          </div>
        </div>
        
        <div className="faq-category">
          <h2>Shipping and Delivery</h2>
          <div className="faq-item">
            <h3>Can I track my shipments?</h3>
            <p>Yes, you can get real-time tracking information on your shipments.</p>
          </div>
          <div className="faq-item">
            <h3>What happens upon order delivery?</h3>
            <p>Upon order delivery, you can seamlessly upload new product information into your dispensary's inventory management software.</p>
          </div>
        </div>
        
        <div className="faq-category">
          <h2>Product Information</h2>
          <div className="faq-item">
            <h3>Can I communicate with distributors about my orders?</h3>
            <p>Yes, you can communicate with distributors directly about your orders.</p>
          </div>
        </div>
        
        <div className="faq-category">
          <h2>Communication and Support</h2>
          <div className="faq-item">
            <h3>How can I get support?</h3>
            <p>For any support inqueries, you can contact at <a href="mailto:info@rudera.co">info@rudera.co</a></p>
          </div>
        </div>

      </div>
    </section>
  );
}

export default FAQPage;
