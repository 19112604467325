import { useState, useEffect } from 'react';


const Quantity = ({
  initialQuantity = 10,
  minQuantity = 5,
  maxQuantity = 100,
  onQuantityChange,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  useEffect(() => {
    setQuantity(initialQuantity);
    onQuantityChange(initialQuantity);
  }, [initialQuantity, onQuantityChange]);

  const handleIncrement = () => {
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1);
      onQuantityChange(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > minQuantity) {
      setQuantity(quantity - 1);
      onQuantityChange(quantity - 1);
    }
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setQuantity(value);
  };

  const handleBlur = () => {
    if (!isNaN(quantity) && quantity >= 10 && quantity <= 100) {
      onQuantityChange(quantity);
    } else {
      setQuantity(initialQuantity); // Reset value if out of range
      onQuantityChange(initialQuantity);
    }
  };

  return (
    <div className="quantity-container">
      <button className="quantity-button" onClick={handleDecrement}>-</button>
      <input
        type="number"
        className="quantity-input"
        value={quantity}
        onChange={handleChange}
        onBlur={handleBlur}
        min={minQuantity}
        max={maxQuantity}
      />
      <button className="quantity-button" onClick={handleIncrement}>+</button>
    </div>
  );
};

export default Quantity;
