import InsideCart from '../components/Cart/Cart';
import NavSidebar from '../components/Dashboard/NavSidebar';
import TopNavbar from '../components/Dashboard/TopNavbar';

function Cart() {
    return (
            <div className="main">
                <TopNavbar/>
                <NavSidebar/>
                <div className="dashboard-content">
                    <InsideCart/>
                </div>
            </div>
        );
}

export default Cart;
