import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ManufacturerOrders({manufacturerId}) {
    const [orders, setOrders] = useState([]);
   

    useEffect(() => {
        fetchOrders();
    }, [manufacturerId]);

    const fetchOrders = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/order/manufacturer/${manufacturerId}`);
            console.log(res)
            setOrders(res.data.orders);
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">Manufacturer Orders</h3>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col-12">
                        <h4>Order List</h4>
                        {/* <ul>
                            {orders.map((order) => (
                                <li key={order._id}>
                                    Order ID: {order.orderId} - Total Amount: ${order.subTotal} - Status: {order.deliveryStatus}
                                </li>
                            ))}
                        </ul> */}
                        <pre>{JSON.stringify(orders, null, 4)}</pre>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ManufacturerOrders;

