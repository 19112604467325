import { useState } from 'react';
import axios from 'axios';

const defaultImg = "/img/kaikas.png";

function ManufacturerView({item}) {

    const [editProduct, setEditProduct] = useState<Boolean>(false);
    const [productInput, setProductInput] = useState(item);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductInput(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
        }
    }

    const handleSave = async () => {
        try {
            setIsSuccess(null);
            setAlertMessage(null);
            let imageUrl = productInput.image_url;
            if (imageFile) {
                // if (imageFile.size < 102400 || imageFile.size > 307200) {
                //     console.log("err");
                //     throw new Error('File size must be between 100KB and 300KB.');
                // }
                const formData = new FormData();
                formData.append('file', imageFile);
                formData.append('productId', item._id);
                formData.append('oldImageUrl', item.image_url);
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/product/image-upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                imageUrl = res.data.imageUrl;
            }
            const updatedProduct = {
                ...productInput,
                image_url: imageUrl, // Not really need since already updated in /image-upload route. DoNot delete this line.
            };
            const res = await axios.patch(`${process.env.REACT_APP_API_URL}/product/${productInput._id}`, updatedProduct);
            if (res.status > 399) {
                throw new Error('Failed to save product details');
            }
            setIsSuccess(true);
            setAlertMessage('Product details saved successfully!');
            setEditProduct(false);
        } catch {
            setProductInput(item);
            setIsSuccess(false);
            setAlertMessage('Error saving product details. Please try again.');
        }
    }

    const handleCancel = () => {
        setEditProduct(false);
        setProductInput(item);
    }

    return (
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-12 col-lg-5">
                    <div className="item-info">
                        <div className="item-thumb text-center">
                        <img src={productInput.image_url ? productInput.image_url : defaultImg} alt={productInput.name} />
                        {editProduct && (
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="form-control mt-3"
                            />
                        )}
                        </div>
                        <div className="mt-3">
                            <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                                <li>
                                    <a className="active" id="nav-home-tab" data-toggle="pill" href="#nav-home">
                                        <h5 className="m-0">{"Product Details"}</h5>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    
                        {/* Tab Content */}
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home">
                                <div className="mt-3">
                                    {editProduct ? (
                                        <>
                                            <label htmlFor="product_details">Product Details</label>
                                            <textarea
                                                name="description"
                                                value={productInput.description}
                                                onChange={handleInputChange}
                                                className="form-control"
                                            />
                                        </>
                                    ) : (
                                        <p>{productInput.description}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    {/* Content */}
                    <div className="content mt-5 mt-lg-0">
                        {editProduct ? (
                            <>
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={productInput.name}
                                    onChange={handleInputChange}
                                    className="form-control"
                                />
                            </>
                        ) : (
                            <h3 className="m-0">{productInput.name}</h3>
                        )}
                        <div className="product-details-container">
                            <div className="price-left">
                                {editProduct ? (
                                    <>
                                        <label htmlFor="price">Price</label>
                                        <input
                                            type="number"
                                            name="price"
                                            value={productInput.price}
                                            onChange={handleInputChange}
                                            className="form-control"
                                        />
                                    </>
                                ) : (
                                    <span className="price">${productInput.price}</span>
                                )}
                            </div>
                            <div className="details-right">
                                {editProduct ? (
                                    <>
                                        <div className="detail-item">
                                            <label htmlFor="thc_level">% THC Level</label>
                                            <input
                                                type="number"
                                                name="thc_level"
                                                value={productInput.thc_level}
                                                onChange={handleInputChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="detail-item">
                                            <label htmlFor="cbd_level">% CBD Level</label>
                                            <input
                                                type="number"
                                                name="cbd_level"
                                                value={productInput.cbd_level}
                                                onChange={handleInputChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="detail-item">
                                            <label htmlFor="strain">Strain</label>
                                            <select id="strain" name="strain" value={productInput.strain} onChange={handleInputChange}>
                                                <option value="Indica">Indica</option>
                                                <option value="Sativa">Sativa</option>
                                                <option value="Hybrid">Hybrid</option>
                                            </select>
                                        </div>
                                        <div className="detail-item">
                                            <label htmlFor="category">Category</label>
                                            <select id="category" name="category" value={productInput.category} onChange={handleInputChange}>
                                                <option value="Flower">Flower</option>
                                                <option value="Edible">Edibles</option>
                                                <option value="Pre-roll">Pre-roll</option>
                                                <option value="Concentrate">Concentrate</option>
                                                <option value="Vape">Vape</option>
                                                <option value="Cartridge">Cartridge</option>
                                                <option value="Accessory">Accessory</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="detail-item">{"Unit Size: " + productInput.unit_size}</div>
                                        <div className="detail-item">{"Category: " + productInput.category}</div>
                                        <div className="detail-item">{"% THC: " + productInput.thc_level}</div>
                                        <div className="detail-item">{"% CBD: " + productInput.cbd_level}</div>
                                        <div className="detail-item">{"Strain: " + productInput.strain}</div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="product-details-container">
                            <div className="price-left">
                                {editProduct ? (
                                    <>
                                        <label htmlFor="amount_in_stock">Amount in Stock</label>
                                        <input
                                            type="number"
                                            name="amount_in_stock"
                                            value={productInput.amount_in_stock}
                                            onChange={handleInputChange}
                                            className="form-control"
                                        />
                                    </>
                                ) : (
                                    <span className="price">{productInput.amount_in_stock} units - In Stock</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {editProduct ? (
                        <div>
                            <p className="d-block btn btn-bordered-white mt-4" onClick={handleSave}>Save</p>
                            <p className="d-block btn btn-bordered-white mt-4" onClick={handleCancel}>Cancel</p>
                        </div>
                    ) : (
                        <p className="d-block btn btn-bordered-white mt-4" onClick={() => setEditProduct(true)}>Edit Product Details</p>
                    )}
                    {alertMessage && (
                        <div className={`alert ${isSuccess ? 'alert-success' : 'alert-danger'}`} role="alert">
                            {alertMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ); 
}

export default ManufacturerView;
