function PrivacyTerms() {
    return (
      <section className="privacy-section">
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>Welcome to Rudera Inc. ("Company", "we", "our", "us")! This Privacy Policy outlines how we collect, use, and protect your information when you use our wholesale marketplace for cannabis dispensaries.</p>
          <h2>Information We Collect</h2>
          <p><strong>Personal Information:</strong> When you register on our platform, we collect personal information such as your name, email address, phone number, and business details.</p>
          <p><strong>Payment Information:</strong> We process payments through secure third-party services and do not store your payment information.</p>
          <p><strong>Usage Data:</strong> We collect data on how you use our platform, including pages visited, time spent on pages, and other similar metrics.</p>
          <p><strong>Cookies:</strong> We use cookies to enhance your experience on our website.</p>
          <h2>How We Use Your Information</h2>
          <p><strong>To Provide Services:</strong> We use your information to connect dispensaries with cannabis suppliers and facilitate transactions.</p>
          <p><strong>To Improve Our Platform:</strong> We use usage data to understand how our platform is used and to improve its functionality and user experience.</p>
          <p><strong>Communication:</strong> We may use your contact information to send you updates about your orders, changes to our services, and promotional information.</p>
          <p><strong>Security:</strong> We use your information to maintain the security and integrity of our platform.</p>
          <h2>Sharing Your Information</h2>
          <p>We do not sell your personal information to third parties. We may share your information with:</p>
          <p><strong>Service Providers:</strong> Third-party vendors who provide services on our behalf, such as payment processing and shipment tracking.</p>
          <p><strong>Compliance with Laws:</strong> If required by law, we may disclose your information to comply with legal obligations.</p>
          <h2>Security</h2>
          <p>We implement industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
          <h2>Your Rights</h2>
          <p>You have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at <a href="mailto:info@rudera.co">info@rudera.co</a>.</p>
          <h2>Changes to This Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@rudera.co">info@rudera.co</a>.</p>
          <h1>Terms of Service</h1>
          <p>Welcome to Rudera Inc.! These Terms of Service ("Terms") govern your use of our wholesale marketplace for cannabis dispensaries.</p>
          <h2>Acceptance of Terms</h2>
          <p>By accessing or using our platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use our platform.</p>
          <h2>Eligibility</h2>
          <p>You must be a licensed dispensary or cannabis supplier to use our platform. By using our platform, you represent and warrant that you meet this eligibility requirement.</p>
          <h2>Use of the Platform</h2>
          <p><strong>Account Registration:</strong> You must create an account to access our services. You are responsible for maintaining the confidentiality of your account information.</p>
          <p><strong>Prohibited Activities:</strong> You agree not to engage in any unlawful activities or use our platform for any illegal purposes.</p>
          <p><strong>Compliance:</strong> You must comply with all applicable laws and regulations when using our platform.</p>
          <h2>Orders and Payments</h2>
          <p><strong>Order Processing:</strong> Orders are processed through our platform. You agree to provide accurate and complete information when placing an order.</p>
          <p><strong>Payments:</strong> All payments are processed through secure third-party services. You agree to pay all applicable fees and charges associated with your orders.</p>
          <h2>Shipping and Delivery</h2>
          <p><strong>Real-Time Tracking:</strong> We provide real-time tracking information for your shipments.</p>
          <p><strong>Delivery:</strong> You are responsible for ensuring that the delivery address provided is accurate and accessible.</p>
          <h2>Intellectual Property</h2>
          <p>All content on our platform, including text, graphics, logos, and images, is the property of Rudera Inc. or its content suppliers and protected by intellectual property laws.</p>
          <h2>Limitation of Liability</h2>
          <p>To the maximum extent permitted by law, Rudera Inc. shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of our platform.</p>
          <h2>Termination</h2>
          <p>We may terminate or suspend your account at any time, without prior notice or liability, if you breach these Terms or engage in any prohibited activities.</p>
          <h2>Governing Law</h2>
          <p>These Terms are governed by and construed in accordance with the laws of The United States of America, without regard to its conflict of law principles.</p>
          <h2>Changes to These Terms</h2>
          <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@rudera.co">info@rudera.co</a>.</p>
        </div>
      </section>
    );
  }
  
  export default PrivacyTerms;
  