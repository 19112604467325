import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from './ItemCard';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setProducts, setLoading, setError } from '../../features/products/productsSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { useUser } from '@clerk/clerk-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import Toolbar from './Toolbar';

function ProductsGrid() {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state: { products: { products } }) => state.products.products);
  const gridView = useAppSelector((state: { products: { gridView } }) => state.products.gridView);
  const { user, isLoaded } = useUser();
  const currentUser = useSelector(selectUser);
  const [isMongoUserLoaded, setIsMongoUserLoaded] = useState(false);

  const [colDefs, setColDefs] = useState<ColDef[]>([
    { 
      field: "name", 
      headerName: "Name",
      cellRenderer: (params: any) => (
        <Link to={{
          pathname: '/item-details',
          state: { item: params.data },
        }}>{params.value}</Link>
      )
    },
    { 
      field: "amount_in_stock", 
      headerName: "In Stock",
    },
    { 
      field: "price", 
      headerName: "Price",
      cellRenderer: (params: any) => {
        return <span>${params.value.toFixed(2)}</span>;
      }
    },
    { 
      field: "unit_size", 
      headerName: "Unit Size",
    },
  ]);

  const autoSizeStrategy = {
    type: 'fitGridWidth' as const,
    defaultMinWidth: 100,
    columnLimits: [
      {
        colId: 'name',
        maxWidth: 550
      },
      {
        colId: 'amount_in_stock',
        maxWidth: 100
      },
      {
        colId: 'price',
        maxWidth: 100
      },
      {
        colId: 'unit_size',
        maxWidth: 100
      },
    ]
  };

  useEffect(() => {
    if (isLoaded && currentUser.mongo_user && Object.keys(currentUser.mongo_user).length > 0) {
      setIsMongoUserLoaded(true);
    }
  }, [isLoaded, currentUser.mongo_user]);

  useEffect(() => {
    if (isLoaded && isMongoUserLoaded) {
      fetchProducts();
    }
  }, [dispatch, user, isLoaded, isMongoUserLoaded]);

  const fetchProducts = async () => {
    dispatch(setLoading(true));
    try {
      let url = `${process.env.REACT_APP_API_URL}/product`;
      if (currentUser.mongo_user.account_type === 'Manufacturer') {
        url += `?manufacturerId=${currentUser.mongo_user.manufacturerId}`;
      }
      const res = await axios.get(url);
      dispatch(setProducts(res.data.products));
    } catch (err) {
      console.log(err);
      dispatch(setError('Failed to fetch products.'));
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (!isMongoUserLoaded) {
    return <div>Loading...</div>;
  }

  const handleButtonClick = (buttonName: string) => {
    switch (buttonName) {
      case 'addProduct':
        
        break;
      case 'export':
        // Handle export
        break;
      default:
        break;
    }
  };

  return (
    <section className="explore-area">
      <Toolbar onButtonClick={handleButtonClick}/>
      <div className="container">
        {gridView ? (
          <div className="ag-theme-quartz" style={{ height: 550, width: '100%' }}>
            <AgGridReact
              rowData={products}
              columnDefs={colDefs}
              autoSizeStrategy={autoSizeStrategy}
            />
          </div>
        ) : (
          <div className="row items">
            {products.map((item, idx) => {
              return (
                <div key={`edt_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                  <ItemCard item={item}/>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}

export default ProductsGrid;
