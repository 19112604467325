import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../app/hooks';
import { selectCartItemsCount } from '../../features/cart/cartSlice';
import { selectUser } from '../../features/user/userSlice';

function  TopNavbar() {
    const user = useAppSelector(selectUser);
    const itemCount = useAppSelector(selectCartItemsCount);

    function NavView() {
        // Only show a cart if the user is a dispensary.
        if (user.mongo_user.account_type === 'Dispensary') {
            return (
                <div className="cart-icon">
                    {itemCount > 0 ? (
                        <Link to="/cart" className="">
                            <FontAwesomeIcon icon={faCartShopping} className="navbar-icon"/>
                        </Link>
                    ) : (
                        <div className="">
                            <FontAwesomeIcon icon={faCartShopping} className="navbar-icon"/>
                        </div>
                    )}
                    {itemCount > 0 && <span className="item-count">{itemCount}</span>}
                </div>
            );
        }
    }

    return (
        <div className="dash-navbar">
            <Link to="/" className="navbar-logo">
                Rudera
            </Link>
            <div className="navbar-middle">
                <input type="text" className="navbar-search" placeholder="Search..." />
            </div>
            <Link to="/profile" className="">
                <FontAwesomeIcon icon={faUser} className="navbar-icon"/>
            </Link>
            <NavView/>
        </div>
    );
}

export default TopNavbar;
