import Header from '../components/Header/Header';
import PrivacyTerms from '../components/Privacy/PrivacyTerms';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

function Privacy() {
    return (
            <div className="main">
                <Header />
                <PrivacyTerms />
                <Footer />
                <ModalSearch />
                <ModalMenu />
                <Scrollup />
            </div>
        );
}

export default Privacy;
