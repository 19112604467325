import NavSidebar from '../components/Dashboard/NavSidebar';
import OrdersTable from '../components/Orders/OrdersTable';
import TopNavbar from '../components/Dashboard/TopNavbar';

function Orders() {
    return (
            <div className="main">
                <TopNavbar/>
                <NavSidebar/>
                <div className="dashboard-content">
                    <OrdersTable/>
                </div>
            </div>
        );
}

export default Orders;
