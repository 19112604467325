import React, { useState } from 'react';
import axios from 'axios';

const DevProductsCSV: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [manufacturerId, setManufacturerId] = useState<string>('6689f4b73d78af8c663388a9'); // big weed

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleManufacturerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManufacturerId(event.target.value);
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file first.');
      return;
    }

    if (!manufacturerId) {
      setUploadStatus('Please enter a manufacturer ID.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('manufacturerId', manufacturerId);

    try {
      setUploadStatus('Uploading...');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/product/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadStatus(`Upload successful. ${response.data.created.count} products created, ${response.data.updated.count} products updated. Total processed: ${response.data.total}`);
    } catch (error) {
      setUploadStatus('Upload failed. Please try again.');
      console.error('Upload error:', error);
    }
  };

  return (
    <div className='activity-area load-more container'>
      <h2>Upload Product CSV</h2>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <input 
        type="text" 
        placeholder="Manufacturer ID" 
        value={manufacturerId} 
        onChange={handleManufacturerIdChange}
      />
      <button onClick={handleUpload} disabled={!file || !manufacturerId}>
        Upload
      </button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default DevProductsCSV;