import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DevUser() {
    const [state, setState] = useState({
        data: {},
        users: []
    });

    const [userInput, setUserInput] = useState({
        clerk_id: '',
        account_type: 'Dispensary',
        dispenserId: '',
        manufacturerId: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: 'Employee'
    });

    useEffect(() => {
        fetchUsers();
    }, [])

    const fetchUsers = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
            setState({
                data: res.data,
                users: res.data.users
            });
        } catch (err) {
            console.log(err);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInput(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = { ...userInput };
            if (payload.account_type === 'Dispensary') {
                delete payload.manufacturerId;
            } else if (payload.account_type === 'Manufacturer') {
                delete payload.dispenserId;
            }
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user`, payload);
            console.log(response.data);
            fetchUsers(); // Refresh the user list
            setUserInput({
                clerk_id: '',
                account_type: 'Dispensary',
                dispenserId: '',
                manufacturerId: '',
                firstName: '',
                lastName: '',
                emailAddress: '',
                role: 'Employee'
            }); // Clear the input
        } catch (error) {
            alert(error.response.data.error)
            console.error('Error:', error.response.data.error);
        }
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/user/${id}`);
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <section className="activity-area load-more">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="intro mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">User Management</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="clerk_id">Clerk ID:</label>
                            <input 
                                type="text" 
                                id="clerk_id"
                                name="clerk_id"
                                value={userInput.clerk_id} 
                                onChange={handleInputChange} 
                                placeholder="Enter Clerk ID"
                                required
                            />
                            <label htmlFor="account_type">Account Type:</label>
                            <select
                                id="account_type"
                                name="account_type"
                                value={userInput.account_type}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="Dispensary">Dispensary</option>
                                <option value="Manufacturer">Manufacturer</option>
                            </select>
                            {userInput.account_type === 'Dispensary' && (
                                <>
                                    <label htmlFor="dispenserId">Dispenser ID:</label>
                                    <input 
                                        type="text" 
                                        id="dispenserId"
                                        name="dispenserId"
                                        value={userInput.dispenserId} 
                                        onChange={handleInputChange} 
                                        placeholder="Enter Dispenser ID"
                                    />
                                </>
                            )}
                            {userInput.account_type === 'Manufacturer' && (
                                <>
                                    <label htmlFor="manufacturerId">Manufacturer ID:</label>
                                    <input 
                                        type="text" 
                                        id="manufacturerId"
                                        name="manufacturerId"
                                        value={userInput.manufacturerId} 
                                        onChange={handleInputChange} 
                                        placeholder="Enter Manufacturer ID"
                                    />
                                </>
                            )}
                            <label htmlFor="firstName">First Name:</label>
                            <input 
                                type="text" 
                                id="firstName"
                                name="firstName"
                                value={userInput.firstName} 
                                onChange={handleInputChange} 
                                placeholder="Enter First Name"
                                required
                            />
                            <label htmlFor="lastName">Last Name:</label>
                            <input 
                                type="text" 
                                id="lastName"
                                name="lastName"
                                value={userInput.lastName} 
                                onChange={handleInputChange} 
                                placeholder="Enter Last Name"
                                required
                            />
                            <label htmlFor="emailAddress">Email Address:</label>
                            <input 
                                type="email" 
                                id="emailAddress"
                                name="emailAddress"
                                value={userInput.emailAddress} 
                                onChange={handleInputChange} 
                                placeholder="Enter Email Address"
                                required
                            />
                            <label htmlFor="role">Role:</label>
                            <select
                                id="role"
                                name="role"
                                value={userInput.role}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="Admin">Admin</option>
                                <option value="Employee">Employee</option>
                            </select>
                            <button type="submit">Add User</button>
                        </form>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <h4>User List</h4>
                        <ul>
                            {state.users.map((user) => (
                                <li key={user._id}>
                                    {user.firstName} {user.lastName} - {user.emailAddress} - {user.account_type} - {user.role}
                                    <button onClick={() => handleDelete(user._id)}>Delete</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DevUser;
