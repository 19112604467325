import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface Product {
  _id: string;
  name: string;
  brand: string;
  manufacturer_id: string;
  image_url: string;
  strain: string;
  category: string;
  price: number;
  thc_level: number;
  cbd_level: number;
  weight: number;
  amount_in_stock: number;
  product_details: string;
}


interface ProductsState {
  products: Product[];
  gridView: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: ProductsState = {
  products: [],
  gridView: true,
  loading: false,
  error: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
    },
    addProduct(state, action: PayloadAction<Product>) {
      state.products.push(action.payload);
    },
    updateProduct(state, action: PayloadAction<Product>) {
      const index = state.products.findIndex((p) => p._id === action.payload._id);
      if (index !== -1) {
        state.products[index] = action.payload;
      }
    },
    removeProduct(state, action: PayloadAction<string>) {
      state.products = state.products.filter((p) => p._id !== action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setGridView(state, action:PayloadAction<boolean>) {
      state.gridView = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const {
  setProducts,
  addProduct,
  updateProduct,
  removeProduct,
  setLoading,
  setGridView,
  setError,
} = productsSlice.actions;

export default productsSlice.reducer;
