import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user/userSlice';
import { SignOutButton, SignedIn } from '@clerk/clerk-react';

function ProfileDetails() {
    const user = useSelector(selectUser);

    return (
        <section className="profile-details">
            <h1>Profile</h1>
            <div>
                <p>Hi {user.firstName}.</p>
                <p>You are a {user.mongo_user.account_type}.</p>
                <p>Your email address: {user.mongo_user.emailAddress}</p>
                <p>Your clerk id: {user.mongo_user.clerk_id}</p>
                <p>Your role: {user.mongo_user.role}</p>
                <p></p>
            </div>
            <SignedIn>
                <SignOutButton/>
            </SignedIn>
        </section>
    );
}

export default ProfileDetails;
