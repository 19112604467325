import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  firstName: '',
  lastName: '',
  mongo_user: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setMongoUser(state, action) {
      state.mongo_user = action.payload.mongo_user;
    },
    clearUser(state) {
      state.id = null;
      state.firstName = '';
      state.lastName = '';
      state.mongo_user = {};
    },
  },
});

export const { setUser, setMongoUser, clearUser } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
