import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../features/user/userSlice';
import DispensaryView from './DispensaryView';
import ManufacturerView from './ManufactuerView';


function ItemDetail() {
    const location = useLocation();
    const { item } = location.state as any;

    const user = useAppSelector(selectUser);

    return (
        <section className="item-details-area">
            {user.mongo_user.account_type === 'Dispensary' 
            ? <DispensaryView item={item}/> 
            : <ManufacturerView item={item}/>}
        </section>
    );
}

export default ItemDetail;
