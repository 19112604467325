import axios from 'axios';
import { useEffect } from 'react';
import MyRoutes from './routers/routes'
import { useDispatch } from 'react-redux';
import { setMongoUser, setUser } from './features/user/userSlice';
import { useUser } from '@clerk/clerk-react';

function App() {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn && user) {
      fetchMongoUser();
      dispatch(setUser({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      }));
    }
  }, [isLoaded, isSignedIn, user, dispatch]);

  const fetchMongoUser = async () => {
    // Gets the Mongo user object associated with the Clerk user id.
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/clerk/${user.id}`);
      dispatch(setMongoUser({mongo_user: res.data.user[0]}));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <MyRoutes />
    </div>
  );
}

export default App;